/* eslint camelcase: "off" */

class ExportFieldOption {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export default ($t) => {
  const id = new ExportFieldOption('contact_id', $t('contact-fields.id'));
  const firstName = new ExportFieldOption(
    'first_name',
    $t('contact-fields.first-name'),
  );
  const lastName = new ExportFieldOption(
    'last_name',
    $t('contact-fields.last-name'),
  );
  const fullName = new ExportFieldOption(
    'name',
    $t('contact-fields.full-name'),
  );
  const email = new ExportFieldOption('email', $t('contact-fields.e-mail'));
  const address = new ExportFieldOption(
    'address',
    $t('contact-fields.address'),
  );
  const phone = new ExportFieldOption(
    'phone',
    $t('contact-fields.phone'),
  );
  const country = new ExportFieldOption(
    'country',
    $t('contact-fields.country'),
  );
  const city = new ExportFieldOption('city', $t('contact-fields.city'));
  const ip = new ExportFieldOption('ip', $t('contact-fields.ip-address'));
  const optinDate = new ExportFieldOption(
    'optin_date',
    $t('contact-fields.optin-date'),
  );
  const pageVisits = new ExportFieldOption(
    'page_visits',
    $t('contact-fields.page-visits'),
  );
  const uniquePageVisits = new ExportFieldOption(
    'unique_page_visits',
    $t('contact-fields.unique-page-visits'),
  );
  const webinarsRegistered = new ExportFieldOption(
    'webinars_registered',
    $t('contact-fields.webinars-registered'),
  );
  const productsAmount = new ExportFieldOption(
    'products_amount',
    $t('contact-fields.products-amount'),
  );
  const memberships = new ExportFieldOption(
    'memberships',
    $t('contact-fields.memberships'),
  );
  const completedLessons = new ExportFieldOption(
    'completed_lessons',
    $t('contact-fields.completed-lessons'),
  );
  const completedCourses = new ExportFieldOption(
    'completed_courses',
    $t('contact-fields.completed-courses'),
  );
  const activityLevel = new ExportFieldOption(
    'activity_level',
    $t('contact-fields.activity-level'),
  );
  const type = new ExportFieldOption('type', $t('contact-fields.type'));
  const revenue = new ExportFieldOption(
    'revenue',
    $t('contact-fields.revenue'),
  );
  const mailsReceived = new ExportFieldOption(
    'mails_received',
    $t('contact-fields.mails-received'),
  );
  const mailsOpened = new ExportFieldOption(
    'mails_opened',
    $t('contact-fields.mails-opened'),
  );
  const mailsClicked = new ExportFieldOption(
    'mails_clicked',
    $t('contact-fields.mails-clicked'),
  );
  const mailsClickRate = new ExportFieldOption(
    'mails_click_rate',
    $t('contact-fields.mails-click-rate'),
  );
  const mailsOpenRate = new ExportFieldOption(
    'mails_open_rate',
    $t('contact-fields.mails-open-rate'),
  );
  const shoppingBehavior = new ExportFieldOption(
    'shopping_behavior',
    $t('contact-fields.shopping-behavior'),
  );
  // const customerTypeDivision = new ExportFieldOption(
  //   "customer_type_division",
  //   $t('contact-fields.unique' )
  // );
  // const tags = new ExportFieldOption("tags", $t('contact-fields.tags' ));
  const createdAt = new ExportFieldOption(
    'created_at',
    $t('contact-fields.created-at'),
  );
  const source = new ExportFieldOption(
    'source',
    $t('contact-fields.source'),
  );

  const sourceId1 = new ExportFieldOption(
    'sourceId1',
    $t('contact-fields.sourceId1'),
  );

  const sourceId2 = new ExportFieldOption(
    'sourceId2',
    $t('contact-fields.sourceId2'),
  );

  const sourceId3 = new ExportFieldOption(
    'sourceId3',
    $t('contact-fields.sourceId3'),
  );

  const sourceId4 = new ExportFieldOption(
    'sourceId4',
    $t('contact-fields.sourceId4'),
  );

  const options = [
    id,
    firstName,
    lastName,
    fullName,
    email,
    phone,
    address,
    country,
    city,
    ip,
    optinDate,
    pageVisits,
    uniquePageVisits,
    webinarsRegistered,
    productsAmount,
    memberships,
    completedLessons,
    completedCourses,
    activityLevel,
    type,
    revenue,
    mailsReceived,
    mailsOpened,
    mailsClicked,
    mailsClickRate,
    mailsOpenRate,
    shoppingBehavior,
    // customerTypeDivision,
    // tags,
    createdAt,
    source,
    sourceId1,
    sourceId2,
    sourceId3,
    sourceId4,
  ];

  const value = [firstName, lastName, email, phone, address, country, city, revenue];

  const xlsx = {
    id: 'xlsx',
    name: 'Microsoft Excel',
  };

  const csv = {
    id: 'csv',
    name: 'CSV',
  };

  const asValue = xlsx;

  const asOptions = [xlsx, csv];

  return {
    options,
    value,
    asValue,
    asOptions,
  };
};
