import { mapState } from 'vuex';
import get from 'lodash/get';

export default {
  computed: {
    ...mapState({
      currency: (state) => state.auth.currency,
    }),
    currencySymbol() {
      return get(this.currency, 'symbol', null) || '';
    },
  },
};
