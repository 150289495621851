<template>
  <ez-content>
    <ez-container>
      <app-widgets />
      <ez-row>
        <ez-col>
          <ez-box>
            <template slot="body">
              <ez-sub-header>
                <template slot="title" v-if="itemsSelected > 0">
                  {{ itemsSelected }}
                  <span>contacts</span>
                  {{ $t("dashboard.contacts-selected") }}
                </template>

                <template slot="controls">
                  <ez-button-group
                    justify="end"
                    v-if="!(empty && (searchText === '' || searchText == null))">
                    <ez-button
                      icon="upload"
                      outline
                      size="small"
                      @click="onImport"
                    >{{ $t("dashboard.import-contacts-button") }}
                    </ez-button>
                    <ez-button
                      icon="download"
                      outline
                      @click="exportOpen = true"
                      size="small"
                    >{{ $t("dashboard.export-contacts-button") }}
                    </ez-button>
                  </ez-button-group>
                </template>

                <template slot="search">
                  <ez-form-item
                    v-if="!(empty && (searchText === '' || searchText == null))"
                    :label="loading ?
                      $t('dashboard.loading-label')
                      : `${totalItems} `
                      + $t('dashboard.results-found-label')"
                    direction="end"
                  >
                    <ez-input
                      type="search"
                      :placeholder="$t('dashboard.search-input-placeholder')"
                      v-model="searchText"
                    ></ez-input>
                  </ez-form-item>
                </template>

                <template slot="title">
                  <div />
                </template>

                <!-- <template slot="filters">
                  <ez-filter-export
                    v-if="false"
                    title
                    colors="contacts"
                    :filters="statisticsFilters"
                  />
                </template> -->

                <template slot="tags">
                  <ez-button-group justify="end">
                    <ez-button
                      type="secondary"
                      outline
                      @click="tags = []; addTagsOpen = true;"
                      v-if="multipleSelection.length > 0"
                    >{{ $t("dashboard.add-tag-button") }}
                    </ez-button>
                    <ez-button
                      type="secondary"
                      outline
                      @click="tags = []; removeTagsOpen = true"
                      v-if="multipleSelection.length > 0"
                    >{{ $t("dashboard.remove-tag-button") }}
                    </ez-button>
                    <ez-button
                      type="secondary"
                      outline
                      @click="unsubscribeOpen = true"
                      v-if="multipleSelection.length > 0 && !isAllUnsubscribe"
                    >{{ $t("dashboard.unsubscribe-button") }}
                    </ez-button>
                    <ez-button
                      type="secondary"
                      outline
                      @click="deleteContactsOpen = true"
                      v-if="multipleSelection.length > 0"
                    >{{ $t("dashboard.delete-button") }}
                    </ez-button>
                    <ez-button
                      type="secondary"
                      outline
                      @click="unselectAllClick"
                      v-if="multipleSelection.length > 0"
                    >{{ $t("dashboard.unselect-all-button") }}
                    </ez-button>
                  </ez-button-group>
                </template>
              </ez-sub-header>

              <div class="contacts-empty" v-if="!loading && empty">
                <ez-empty-page>
                  <template slot="image">
                    <div v-html="noContactsIcon"></div>
                  </template>
                  <template slot="title">
                    {{
                      searchText === '' || searchText == null
                        ? $t("dashboard.contacts-empty-title")
                        : $t("dashboard.contacts-not-found-title")
                    }}
                  </template>
                  <template slot="description">
                    {{
                      searchText === '' || searchText == null
                        ? $t("dashboard.contacts-empty-body")
                        : $t("dashboard.contacts-not-found-body")
                    }}
                  </template>
                </ez-empty-page>

                <template v-if="searchText === '' || searchText == null">
                  <ez-button
                    v-if="isCreateFunnel"
                    type="primary"
                    @click="goToFunnels"
                  >{{ $t("dashboard.create-funnel-button") }}
                  </ez-button>
                  <p v-if="isCreateFunnel && isImportContacts">{{ $t("dashboard.contacts-or") }}</p>
                  <ez-button
                    v-if="isImportContacts"
                    icon="upload"
                    outline
                    size="small"
                    @click="onImport"
                  >{{ $t("dashboard.import-contacts-button") }}
                  </ez-button>
                  <p></p>
                </template>
              </div>

              <div class="contacts-empty" v-if="loading">
                <div class="contacts-empty__container">
                  <div class="contacts-empty__note">
                    <ez-preloader :value="true" :stop="false" />
                  </div>
                </div>
              </div>
              <ez-table
                v-if="!empty && !loading"
                class="table"
                :data="items"
                row-key="id"
                ref="contactsTable"
                stripe
                @selection-change="handleSelectionChange"
                @sort-change="handleSortChange"
                :row-class-name="rowClassName"
                :default-sort="sortParams"
              >
                <div slot="empty">{{ $t("no-data") }}</div>
                <ez-table-column type="selection" :width="40" />

                <ez-table-column
                  prop="name"
                  fixed
                  sortable="custom"
                  :label="$t('contact-fields.contact')"
                  :min-width="135"
                  :sort-orders="['ascending', 'descending']"
                >
                  <template slot-scope="scope">
                    <div class="contacts-link" @click="editSingleUserClick(scope.row)">
                      <div>
                        {{ scope.row.firstName }}
                        {{ scope.row.lastName }}
                      </div>
                      <span>{{ scope.row.email }}</span>
                    </div>
                  </template>
                </ez-table-column>

                <ez-table-column
                  v-for="(item, index) in activeFields"
                  :sortable="item.sortable ? 'custom' : null"
                  :width="155"
                  :key="index"
                  :prop="item.name"
                  :label="localizedFieldNames[item.name] || item.displayName"
                  :sort-orders="['ascending', 'descending']"
                >
                  <template slot="header" slot-scope="{}">
                    <span
                      class="ellipsis-title"
                      :title="localizedFieldNames[item.name] || item.displayName"
                    >
                      {{ localizedFieldNames[item.name] || item.displayName }}
                    </span>
                  </template>
                  <template slot-scope="scope">
                    <div class="contact__tags" v-if="item.name === 'tags'">
                      <div
                        v-for="tag in scope.row.tags"
                        class="contact__tag-item"
                        :key="tag+scope.row.id"
                        :title="getContactTag(tag).name"
                      >
                        <span>
                          {{ getContactTag(tag).name }}
                        </span>
                      </div>
                    </div>
                    <div v-else> {{ getContactFieldValue(scope.row, item) }}</div>
                  </template>
                </ez-table-column>

                <ez-table-column
                  prop="firstName"
                  fixed="right"
                  :width="40"
                  class-name="icons-fix"
                >
                  <template slot="header" slot-scope="{}">
                    <div class="settings settings--cog">
                      <ez-dropdown @input="onColumnsMenuDisplayChange">
                        <ez-icon name="cog" size="20" color="#8095AA" />
                        <ez-dropdown-menu
                          fixed-position="bottom"
                          :max-height="400"
                          slot="menu"
                          class="no-offset-fix"
                        >
                          <template slot="header">
                            {{ $t("dashboard.columns-dropdown-label") }}
                          </template>
                          <ez-dropdown-item
                            v-for="(item, index) in tempFields"
                            :key="index"
                            outer-event
                          >
                            <template slot="custom">
                              <ez-checkbox
                                v-model="item.visible"
                              >
                                {{ localizedFieldNames[item.name] || item.displayName }}
                              </ez-checkbox>
                            </template>
                          </ez-dropdown-item>
                          <template slot="controls" slot-scope="{ active }">
                            <ez-button-group justify="center">
                              <ez-button
                                type="primary"
                                size="small"
                                @click="saveTempFields(); active(false);"
                              >
                                {{ $t("dashboard.columns-save-selection-button") }}
                              </ez-button>
                            </ez-button-group>
                          </template>
                        </ez-dropdown-menu>
                      </ez-dropdown>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div class="settings">
                      <ez-dropdown>
                        <ez-icon name="ellipsis-v" size="20" color="#8095AA" />
                        <ez-dropdown-menu slot="menu">
                          <ez-dropdown-item
                            icon="pen"
                            @click="editSingleUserClick(scope.row)"
                          >{{ $t('edit-button') }}
                          </ez-dropdown-item>
                          <ez-dropdown-item
                            v-if="scope.row.subscribeStatus != 3"
                            icon="user"
                            @click="subscribeSingleUserClick(scope.row)"
                          >{{ $t('subscribe-button') }}
                          </ez-dropdown-item>
                          <ez-dropdown-item
                            v-if="scope.row.subscribeStatus == 3"
                            icon="user-slash"
                            @click="unsubscribeSingleUserClick(scope.row)"
                          >{{ $t('unsubscribe-button') }}
                          </ez-dropdown-item>
                          <ez-dropdown-item
                            icon="trash"
                            type="danger"
                            iconColor="#EE5253"
                            @click="deleteSingleUserClick(scope.row)"
                          >{{ $t('delete-button') }}
                          </ez-dropdown-item>
                        </ez-dropdown-menu>
                      </ez-dropdown>
                    </div>
                  </template>
                </ez-table-column>
              </ez-table>

              <ez-pagination
                v-if="!empty"
                :page="pageNumber"
                :page-count="totalPages"
                :page-range="pageRange"
                :limit="pageSize"
                :limit-options="limitOptions"
                @change="onChangeTablePage"
              />

              <div class="dialogs">
                <ez-dialog :open.sync="addTagsOpen">
                  <template slot="header">
                    <!-- TODO: вывести актуальную иконку по макету -->
                    <ez-icon size="20" name="clone" color="#555555" />
                    {{ $t("dashboard.add-tags-dialog-header") }}
                  </template>
                  <template>
                    <ez-select
                      v-model="tags"
                      :multiple="true"
                      :label="$t('dashboard.add-tags-dialog-select-label')"
                      :taggable="true"
                      :options="tagsOptions"
                      item-label="name"
                      track-by="id"
                      @remove="handleRemoveTag"
                      @tag="handleNewTag"
                    />
                  </template>
                  <template slot="footer">
                    <ez-button-group justify="end">
                      <ez-button
                        @click="addTagsOpen=false"
                        type="secondary"
                      >{{ $t("dashboard.cancel-button") }}
                      </ez-button>
                      <ez-button
                        @click="bulkAttachTags"
                        type="primary"
                        :disabled="tags.length === 0"
                      >{{ $t("dashboard.add-button") }}
                      </ez-button>
                    </ez-button-group>
                  </template>
                </ez-dialog>

                <ez-dialog :open.sync="removeTagsOpen">
                  <template slot="header">
                    <!-- TODO: вывести актуальную иконку по макету -->
                    <ez-icon size="20" name="clone" color="#555555" />
                    {{ $t("dashboard.remove-tags-dialog-header") }}
                  </template>
                  <template>
                    <ez-select
                      v-model="tags"
                      :options.sync="tagsOptions"
                      :multiple="true"
                      :taggable="true"
                      :label="$t('dashboard.remove-tags-dialog-select-label')"
                      item-label="name"
                      track-by="id"
                    ></ez-select>
                  </template>
                  <template slot="footer">
                    <ez-button-group justify="end">
                      <ez-button
                        @click="removeTagsOpen=false"
                        type="secondary"
                      >{{ $t("dashboard.cancel-button") }}
                      </ez-button>
                      <ez-button
                        @click="bulkDetachTags"
                        type="danger"
                        :disabled="tags.length === 0"
                      >{{ $t("dashboard.remove-button") }}
                      </ez-button>
                    </ez-button-group>
                  </template>
                </ez-dialog>

                <ez-dialog :open.sync="deleteContactsOpen">
                  <template slot="header">
                    <ez-icon size="20" name="trash" color="#555555" />
                    {{ $t("dashboard.delete-dialog-header") }}
                  </template>
                  <template>
                    {{ $t("dashboard.delete-dialog-body", { msg: multipleSelectionLabel }) }}
                  </template>
                  <template slot="footer">
                    <ez-button-group justify="end">
                      <ez-button
                        @click="deleteContactsOpen=false"
                        type="secondary"
                      >{{ $t("dashboard.cancel-button") }}
                      </ez-button>
                      <ez-button
                        @click="bulkDeleteContacts"
                        type="danger"
                      >{{ $t("dashboard.delete-button") }}
                      </ez-button>
                    </ez-button-group>
                  </template>
                </ez-dialog>

                <ez-dialog :open.sync="confirmDeleteSingleContact">
                  <template slot="header">
                    <ez-icon size="20" name="trash" color="#555555" />
                    {{ $t("dashboard.delete-dialog-header") }}
                  </template>
                  <template>
                    {{ $t("dashboard.delete-dialog-body", { msg: 1 }) }}
                  </template>
                  <template slot="footer">
                    <ez-button-group justify="end">
                      <ez-button
                        @click="cancelSingleRemoveContact"
                        type="secondary"
                      >{{ $t("dashboard.cancel-button") }}
                      </ez-button>
                      <ez-button
                        @click="confirmDeleteContact"
                        type="danger"
                      >{{ $t("dashboard.delete-button") }}
                      </ez-button>
                    </ez-button-group>
                  </template>
                </ez-dialog>

                <ez-dialog :open.sync="unsubscribeOpen">
                  <template slot="header">
                    <!-- TODO: вывести актуальную иконку по макету -->
                    <ez-icon size="20" name="user-slash" color="#555555" />
                    {{ $t("dashboard.unsub-dialog-header") }}
                  </template>
                  <template>
                    {{ $t("dashboard.unsub-dialog-body", { msg: multipleSelectionLabel }) }}
                  </template>
                  <template slot="footer">
                    <ez-button-group justify="end">
                      <ez-button
                        @click="unsubscribeOpen=false"
                        type="secondary"
                      >{{ $t("dashboard.cancel-button") }}
                      </ez-button>
                      <ez-button
                        @click="bulkUnsubscribeContacts"
                        type="danger"
                      >{{ $t("dashboard.unsubscribe-button") }}
                      </ez-button>
                    </ez-button-group>
                  </template>
                </ez-dialog>

                <ez-dialog :open.sync="exportOpen" size="medium">
                  <template slot="header">
                    <ez-icon size="20" name="download" color="#555555" />
                    {{ $t('dashboard.export-dialog-header') }}
                  </template>
                  <template>
                    <template v-if="!exportInProgress">
                      <ez-form-item :label="$t('dashboard.export-dialog-fields-select-label')">
                        <ez-select
                          :multiple="true"
                          :taggable="true"
                          :options="exportOptions"
                          v-model="exportValue"
                          track-by="id"
                          item-label="name"
                        />
                      </ez-form-item>

                      <ez-form-item>
                        <ez-row class="mb-3">
                          <ez-col size="8">
                            <ez-checkbox v-model="exportWithTags">
                              {{ $t('Include Tags in export') }}
                            </ez-checkbox>
                          </ez-col>
                        </ez-row>
                        <ez-row>
                          <ez-col size="9">
                            <ez-select
                              v-model="exportAsValue"
                              :label="$t('dashboard.export-dialog-download-format-label')"
                              :options="exportAsOptions"
                              item-label="name"
                              track-by="id"
                            />
                          </ez-col>
                        </ez-row>
                      </ez-form-item>
                    </template>
                    <template v-else>
                      <div class="export-in-progress">
                        <div class="export-in-progress__spinner">
                          <ez-preloader :value="true" :stop="true" />
                        </div>
                        <div class="export-in-progress__text">
                          {{ $t('dashboard.export-in-progress-text') }}
                          <br />
                          {{ $t('dashboard.export-in-progress-text-wait') }}
                        </div>
                      </div>
                    </template>
                  </template>
                  <template slot="footer">
                    <ez-button-group justify="end" v-if="!exportInProgress">
                      <ez-button
                        @click="preCloseExport"
                        type="secondary"
                      >{{ $t("dashboard.cancel-button") }}
                      </ez-button>
                      <ez-button
                        @click="createExport"
                        type="primary"
                      >{{ $t("dashboard.export-button") }}
                      </ez-button>
                    </ez-button-group>
                  </template>
                </ez-dialog>
              </div>
            </template>
          </ez-box>
        </ez-col>
      </ez-row>
    </ez-container>
  </ez-content>
</template>

<script>
import { URL_API_CONTACTS, URL_FUNNELS } from '@dmant/ez-env-common';
import * as subscriptionStatus from '@/store/contacts/subscriptionStatus';
import currencyMixin from '@/mixins/currency';
import Widgets from './Widgets.vue';
// eslint-disable-next-line
import noContactsIcon from '!raw-loader!@/assets/icons/no-contacts.svg';
import * as injectNames from '../../services/names';
import * as authGetters from '../../store/auth/getters';
import * as contactsGetters from '../../store/contacts/getters';
import * as contactsActions from '../../store/contacts/actions';
import * as navActions from '../../store/nav/actions';
import * as tagsActions from '../../store/tags/actions';
import * as tagsGetters from '../../store/tags/getters';

import exportHelper from './export';
// import filters from './filters';

const STATUS_SUBSCRIBE = 3;

export default {
  name: 'AppDashboard',
  mixins: [currencyMixin],
  dependencies: [
    injectNames.CONTACTS_API,
    injectNames.TAGS_API,
    injectNames.FORMATTERS,
    injectNames.FILE_DOWNLOADER,
    '$log',
  ],
  components: {
    AppWidgets: Widgets,
  },
  data() {
    return {
      noContactsIcon,
      checkbox: false,
      limit: 10,
      limitOptions: [10, 20, 30],
      multipleSelection: [],
      selected: 0,
      addTagsOpen: false,
      removeTagsOpen: false,
      deleteContactsOpen: false,
      confirmDeleteSingleContact: false,
      singleRemoveContactId: null,
      unsubscribeOpen: false,
      exportOpen: false,
      exportWithTags: false,
      searchText: '',
      searchTextTimer: null,
      pageRange: 7,
      exportValue: [],
      exportOptions: [],
      exportAsValue: null,
      exportAsOptions: [],
      tags: [],
      settings: false,
      // statisticsFilters: filters,
      prevSelection: null,
      tempFields: [],
      sortParams: {
        prop: 'optinDate',
        order: 'descending',
      },

      exportId: null,
      exportInProgress: false,

      localizedFieldNames: {
        firstName: this.$t('contact-fields.first-name'),
        lastName: this.$t('contact-fields.last-name'),
        email: this.$t('contact-fields.e-mail'),
        phone: this.$t('contact-fields.phone'),
        country: this.$t('contact-fields.country'),
        'page-visits': this.$t('contact-fields.page-visits'),
        'unique-page-visits': this.$t('contact-fields.unique-page-visits'),
        'webinars-registered': this.$t('contact-fields.webinars-registered'),
        purchases: this.$t('contact-fields.purchases'),
        memberships: this.$t('contact-fields.memberships'),
        'completed-lessons': this.$t('contact-fields.completed-lessons'),
        'completed-courses': this.$t('contact-fields.completed-courses'),
        'activity-level': this.$t('contact-fields.activity-level'),
        type: this.$t('contact-fields.type'),
        logins: this.$t('contact-fields.number-of-logins'),
        sessions: this.$t('contact-fields.number-of-sessions'),
        'mails-received': this.$t('contact-fields.mails-received'),
        'webinars-attended': this.$t('contact-fields.memberships'),
        revenue: this.$t('contact-fields.revenue'),
        optinDate: this.$t('contact-fields.optin-date'),
        sourceId1: this.$t('contact-fields.sourceId1'),
        sourceId2: this.$t('contact-fields.sourceId2'),
        sourceId3: this.$t('contact-fields.sourceId3'),
        sourceId4: this.$t('contact-fields.sourceId4'),
      },
    };
  },
  created() {
    this.loadContacts();
    this.loadTags();
    this.setBreadCrumbs();

    const exportData = exportHelper(this.$t.bind(this));
    this.exportValue = exportData.value;
    this.exportOptions = exportData.options;
    this.exportAsValue = exportData.asValue;
    this.exportAsOptions = exportData.asOptions;
  },
  beforeMount() {
    // set window title
    window.document.title = this.$t('app-public-title');
  },
  methods: {
    getContactTag(tag) {
      return this.tagsOptions.find((item) => item.id === tag);
    },
    setBreadCrumbs() {
      this.$store.dispatch(`nav/${navActions.SET_BREADCRUMBS}`, [
        {
          name: 'Dashboard',
          status: '',
          href: '/',
        },
      ]);
    },
    onImport() {
      this.$router.push({ name: 'new-import' });
    },
    goToFunnels() {
      window.location.href = `${URL_FUNNELS}/funnels`;
    },
    loadContacts(params = {}) {
      // save current selection
      this.prevSelection = this.multipleSelection.map((item) => item.id);

      const actualParams = {
        searchText: this.searchText,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...params,
      };
      this.$store.dispatch(
        `contacts/${contactsActions.GET_ITEMS}`,
        actualParams,
      );
    },

    // handle tags
    loadTags() {
      this.$store.dispatch(`tags/${tagsActions.GET_ITEMS}`);
    },

    handleRemoveTag(tag) {
      const newTags = this.tags.filter((item) => item.id !== tag.id);
      this.tags = newTags;
    },

    handleNewTag(tag) {
      const api = this[injectNames.TAGS_API];
      const data = {
        name: tag,
        user: this.currentUserId,
      };
      api
        .createTag(data)
        .then((item) => {
          // append created tag item to tags store
          this.$store.dispatch(`tags/${tagsActions.APPEND_ITEM}`, item);
          // apend item to tags values
          this.tags.push(item);
        })
        .catch((err) => {
          this.$log({ err });
        });
    },

    editSingleUserClick(item) {
      const { id: contactId } = item;
      this.$router.push({ name: 'overview', params: { contactId } });
    },

    deleteSingleUserClick(item) {
      this.confirmDeleteSingleContact = true;
      this.singleRemoveContactId = item.id;
    },

    cancelSingleRemoveContact() {
      this.confirmDeleteSingleContact = false;
      this.singleRemoveContactId = null;
    },

    confirmDeleteContact() {
      this[injectNames.CONTACTS_API].deleteContacts([this.singleRemoveContactId]).then(() => {
        this.loadContacts();
        const message = this.$t('notifications.contact-remove-success');
        this.$notify({
          data: {
            type: 'success',
            content: message,
          },
        });

        this.cancelSingleRemoveContact();
      });
    },

    subscribeSingleUserClick(item) {
      this[injectNames.CONTACTS_API].subscribeContacts([item.id]).then(() => {
        this.loadContacts();
        const message = this.$t('notifications.contact-subscribe-success');
        this.$notify({
          data: {
            type: 'success',
            content: message,
          },
        });
      });
    },

    unsubscribeSingleUserClick(item) {
      this[injectNames.CONTACTS_API].unsubscribeContacts([item.id]).then(() => {
        this.loadContacts();
        const message = this.$t('notifications.contact-unsubscribe-success');
        this.$notify({
          data: {
            type: 'success',
            content: message,
          },
        });
      });
    },

    bulkDeleteContacts() {
      this.deleteContactsOpen = false;
      const ids = this.multipleSelection.map((item) => item.id);
      this[injectNames.CONTACTS_API]
        .deleteContacts(ids)
        .then(() => {
          // load contacts
          this.loadContacts();
          // clear bulk selection
          this.multipleSelection = [];
          // display success notification
          const message = this.$t('notifications.contacts-remove-success');
          this.$notify({
            data: {
              type: 'success',
              content: message,
            },
          });
        })
        .catch(() => {
          const message = this.$t('notifications.contact-update-error');
          this.$notify({
            data: {
              type: 'error',
              content: message,
            },
          });
        });
    },

    bulkUnsubscribeContacts() {
      this.unsubscribeOpen = false;
      const ids = this.multipleSelection.map((item) => item.id);
      this[injectNames.CONTACTS_API]
        .unsubscribeContacts(ids)
        .then(() => {
          this.loadContacts();
          const message = this.$t('notifications.contacts-unsubscribe-success');
          this.$notify({
            data: {
              type: 'success',
              content: message,
            },
          });
        })
        .catch(() => {
          const message = this.$t('notifications.contact-update-error');
          this.$notify({
            data: {
              type: 'error',
              content: message,
            },
          });
        });
    },

    bulkAttachTags() {
      // close dialog
      this.addTagsOpen = false;
      // attach tags to selected contacts
      const contactsIds = this.multipleSelection.map((item) => item.id);
      const tagsIds = this.tags.map((item) => item.id);
      this[injectNames.CONTACTS_API]
        .attachTags(contactsIds, tagsIds)
        .then(() => {
          this.loadContacts();
          const message = this.$t('notifications.contacts-update-success');
          this.$notify({
            data: {
              type: 'success',
              content: message,
            },
          });
        })
        .catch(() => {
          const message = this.$t('notifications.contacts-update-error');
          this.$notify({
            data: {
              type: 'error',
              content: message,
            },
          });
        });
    },

    bulkDetachTags() {
      // close dialog
      this.removeTagsOpen = false;
      // attach tags to selected contacts
      const contactsIds = this.multipleSelection.map((item) => item.id);
      const tagsIds = this.tags.map((item) => item.id);
      this[injectNames.CONTACTS_API]
        .detachTags(contactsIds, tagsIds)
        .then(() => {
          this.loadContacts();
          const message = this.$t('notifications.contacts-update-success');
          this.$notify({
            data: {
              type: 'success',
              content: message,
            },
          });
        })
        .catch(() => {
          const message = this.$t('notifications.contacts-update-error');
          this.$notify({
            data: {
              type: 'error',
              content: message,
            },
          });
        });
    },

    unselectAllClick() {
      this.$refs.contactsTable.clearSelection();
    },
    onChangeTablePage({ limit, page }) {
      this.loadContacts({
        pageSize: limit,
        pageNumber: page,
        sortProp: this.sortParams.prop,
        sortOrder: this.sortParams.order,
      });
    },
    onSelect() {
    },
    handleSelectionChange(value) {
      this.multipleSelection = value;
    },
    handleSortChange({ prop, order }) {
      this.sortParams.prop = prop || this.sortParams.prop;
      this.sortParams.order = order || this.sortParams.order;
      this.loadContacts({
        pageNumber: 1,
        sortProp: this.sortParams.prop,
        sortOrder: this.sortParams.order,
      });
    },
    rowClassName({ row }) {
      let classes = [];
      if (this.multipleSelection.some((selected) => selected.id === row.id)) {
        classes.push('is-selected');
      }

      // init sub status
      const { subscribeStatus } = row || {};
      const isSubscribed = subscribeStatus === subscriptionStatus.SUBSCRIBE_STATUS_CONFIRMED;
      if (!isSubscribed) {
        classes.push('is-disabled');
      }

      if (row.limited) {
        classes.push('is-limited');
        classes = classes.filter((item) => item !== 'is-selected');
      }

      return classes.join(' ');
    },

    // fields related methods
    saveFields() {
      // this.activeFields = this.tempActiveFields;
      // this.settings = false;
      this.$store.dispatch(
        `contacts/${contactsActions.SAVE_FIELDS}`,
        this.allFields,
      );
      this.$notify({
        data: {
          type: 'success',
          content: this.$t('notifications.columns-updated'),
        },
      });
    },

    saveTempFields() {
      let updatedFieldsCount = 0;
      // eslint-disable-next-line
      for (let i = 0; i < this.tempFields.length; i++) {
        const actualField = this.allFields[i];
        const tempField = this.tempFields[i];
        if (tempField != null && actualField != null) {
          if (actualField.visible !== tempField.visible) {
            // eslint-disable-next-line
            updatedFieldsCount++;
          }
          actualField.visible = tempField.visible;
        }
      }
      if (updatedFieldsCount) {
        this.saveFields();
      }
    },

    getContactFieldValue(contact, field) {
      const options = {
        currencySymbol: this.currencySymbol,
        timeFormat: field.timeFormat,
      };
      if (field.formatter && this[injectNames.FORMATTERS][field.formatter]) {
        const formatter = this[injectNames.FORMATTERS][field.formatter];
        return formatter(contact[field.name], options);
      }
      return contact[field.name];
    },

    // export methods
    preCreateExport() {
      this.createExport();
    },

    createExport() {
      const params = {
        fields: this.exportValue.map((item) => item.id),
        format: this.exportAsValue.id,
        tags: this.exportWithTags,
      };
      this.exportInProgress = true;
      this.$store
        .dispatch(`contacts/${contactsActions.CREATE_EXPORT}`, params)
        .then(({ id }) => {
          this.exportId = id;
          if (this.exportItem) {
            setTimeout(() => {
              this.checkExportStatus(id);
            }, 5 * 1000);
          }
        })
        .catch((err) => {
          this.exportInProgress = false;
          this.$log(err);
        });
    },

    checkExportStatus(currentExportId) {
      if (this.exportId === currentExportId) {
        this.$store
          .dispatch(`contacts/${contactsActions.CHECK_EXPORT}`, this.exportId)
          .then((resp) => {
            if (this.exportId != null) {
              const { status, filename } = resp;
              switch (status) {
                case 'done': {
                  const downloadUrl = `${URL_API_CONTACTS}/${filename}`;
                  this[injectNames.FILE_DOWNLOADER](downloadUrl);
                  this.closeExport();
                  const message = this.$t(
                    'notifications.contacts-export-success',
                  );
                  this.$notify({
                    data: {
                      type: 'success',
                      content: message,
                    },
                  });
                }

                  break;
                case 'process':
                case 'new':
                  setTimeout(() => {
                    this.checkExportStatus(currentExportId);
                  }, 5 * 1000);
                  break;
                case 'error':
                  this.$notify({
                    data: {
                      type: 'error',
                      content: this.$t('notifications.contacts-export-error'),
                    },
                  });
                  this.closeExport();
                  break;
                default:
                  break;
              }
            }
          })
          .catch((err) => {
            this.$log(err);
          });
      }
    },

    preCloseExport() {
      // TODO: display confirmation dialog
      this.closeExport();
    },

    closeExport() {
      this.exportOpen = false;
    },

    onColumnsMenuDisplayChange(visible) {
      if (visible) {
        this.tempFields = this.allFields.map((item) => ({
          ...item,
        }));
      }
    },
  },
  computed: {
    isCreateFunnel() {
      return this.$ability.can('create', 'Funnels');
    },
    isImportContacts() {
      return this.$ability.can('create', 'ContactsBulk');
    },
    isAllUnsubscribe() {
      return this.multipleSelection.every((_) => _.subscribeStatus !== STATUS_SUBSCRIBE);
    },
    items() {
      const items = this.$store.getters[`contacts/${contactsGetters.ITEMS}`];
      return items;
    },
    tagsOptions() {
      const items = this.$store.getters[`tags/${tagsGetters.ITEMS}`];
      return items;
    },
    currentUserId() {
      const user = this.$store.getters[`auth/${authGetters.CURRENT_USER}`];
      return user ? user.legacyId : undefined;
    },
    totalItems() {
      return this.$store.getters[`contacts/${contactsGetters.TOTAL_ITEMS}`];
    },
    empty() {
      // return true;
      return this.totalItems === 0;
    },
    totalPages() {
      return this.$store.getters[`contacts/${contactsGetters.TOTAL_PAGES}`];
    },
    loading() {
      return this.$store.getters[`contacts/${contactsGetters.LOADING}`];
    },
    pageSize() {
      return this.$store.getters[`contacts/${contactsGetters.PAGE_SIZE}`];
    },
    pageNumber() {
      return this.$store.getters[`contacts/${contactsGetters.PAGE_NUMBER}`];
    },
    itemsSelected() {
      return this.multipleSelection.length;
    },

    // fields related props
    allFields() {
      return this.$store.getters[`contacts/${contactsGetters.FIELDS}`];
    },
    activeFields() {
      return this.allFields.filter((item) => item.visible);
    },

    multipleSelectionLabel() {
      if (this.multipleSelection && this.multipleSelection.length === 0) {
        return '0 contacts';
      }
      if (this.multipleSelection.length === 1) {
        return '1 contact';
      }
      return `${this.multipleSelection.length} contacts`;
    },

    // export
    exportItems() {
      return this.$store.getters[`contacts/${contactsGetters.EXPORT_ITEMS}`];
    },
    exportItem() {
      return this.exportId == null ? null : this.exportItems[this.exportId];
    },
  },
  watch: {
    searchText(newValue) {
      if (this.searchTextTimer) {
        clearTimeout(this.searchTextTimer);
        this.searchTextTimer = null;
      }
      this.searchTextTimer = setTimeout(() => {
        this.loadContacts({
          pageNumber: 1,
          searchText: newValue,
          sortProp: this.sortParams.prop,
          sortOrder: this.sortParams.order,
        });
      }, 250);
    },

    // handle items change for selection initialization
    items(newValue) {
      // if items available and data to select too => update table selection
      if (
        newValue
        && newValue.length > 0
        && this.prevSelection
        && this.prevSelection.length > 0
      ) {
        const { prevSelection } = this;
        // init rows to select
        const rowsToSelect = newValue.filter(
          // eslint-disable-next-line
          item => prevSelection.find(id => id === item.id) != null
        );
        // update table selection
        this.$nextTick(() => {
          rowsToSelect.forEach((row) => {
            this.$refs.contactsTable.toggleRowSelection(row);
          });
        });
      }
    },

    currentUserId() {
      this.loadContacts();
    },

    exportOpen(newValue, oldValue) {
      if (!newValue && newValue !== oldValue) {
        this.exportId = null;
        this.exportInProgress = false;
      }
    },
  },
  // filters: {
  //   normalText(value) {
  //     const name = value
  //       .replace(/([a-z\d])([A-Z])/g, '$1 $2')
  //       .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1 $2')
  //       .toLowerCase();

  //     return name.charAt(0).toUpperCase() + name.slice(1);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.export-in-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;

  &__spinner {
    margin: 40px 0;
  }

  &__text {
    margin-top: 34px;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #555555;
  }
}

.contacts-link {
  cursor: pointer;
}

.contact {
  &__tag-item {
    padding: 0 8px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    line-height: 1;
    background-color: #26c281;
    font-size: 14px;
    color: #ffffff;
    max-width: 100%;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}
</style>
