<template>
  <ez-facts
    v-if="isShowWidgets"
    :items="displayWidgets"
    :active-ids="activeWidgetsIds"
    :max="4"
    :edited="isEditWidgets"
    @change="onWidgetsUpdate"
    editable
  >
    <template slot="add-button-text">
      {{ $t("dashboard.widgets.add-button-text") }}
    </template>
    <template slot="dialog-title" slot-scope="props">
      {{ $t("dashboard.widgets.dialog-title", props) }}
    </template>
    <template slot="discard-button-text">
      {{ $t("dashboard.widgets.discard-button-text") }}
    </template>
    <template slot="save-button-text">
      {{ $t("dashboard.widgets.save-button-text") }}
    </template>
  </ez-facts>
</template>

<script>
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { createNamespacedHelpers } from 'vuex';
import currencyMixin from '@/mixins/currency';

import { LOAD_WIDGETS, CHANGE_WIDGETS } from '@/store/contacts/actions';
import { WIDGETS, USER_WIDGETS } from '@/store/contacts/getters';
import * as injectNames from '../../services/names';

const { mapGetters, mapActions } = createNamespacedHelpers('contacts');

export default {
  name: 'AppDashboardWidgets',
  dependencies: [injectNames.CONTACTS_API],
  mixins: [currencyMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getWidgets: WIDGETS,
      getUserWidgets: USER_WIDGETS,
    }),
    isShowWidgets() {
      return this.$ability.can('view', 'ContactsUserWidgets');
    },
    isEditWidgets() {
      return this.$ability.can('edit', 'ContactsUserWidgets');
    },
    displayWidgets() {
      const existsImages = this.images.keys();
      return this.getWidgets.map((item, index) => {
        const {
          id, type, name, value,
        } = item;
        const userWidget = this.userWidgetsByWidgetId[id];
        const key = type.toLowerCase();
        const image = `./${key}.svg`;
        const url = existsImages.includes(image) ? this.images(image) : undefined;
        const i18nPath = `dashboard.widgets.types.${key}`;
        const userWidgetValue = get(userWidget, 'value', value);

        // format widget value
        let strValue = null;
        switch (type) {
          case 'CUSTOMERS_BY_LEADS_RATIO':
            // percents reprecentation
            strValue = `${(Number.parseFloat(userWidgetValue) * 100)
              .toLocaleString({
                style: 'percent',
                maximumFractionDigits: 2,
              })}%`;
            break;
          case 'REVENUE_PER_LEAD':
            // currency representation
            strValue = `${this.currencySymbol}${
              Number.parseFloat(userWidgetValue)
                .toLocaleString({
                  maximumFractionDigits: 2,
                })
            }`;
            break;
          default:
            strValue = `${userWidgetValue}`;
        }
        return {
          id: index,
          icon: url ? undefined : 'eye',
          url,
          text: this.$te(i18nPath) ? this.$t(i18nPath) : name,
          header: strValue,
          'widget-id': id,
          'user-widget-id': get(userWidget, 'id', null),
        };
      });
    },
    userWidgetsByWidgetId() {
      return keyBy(this.getUserWidgets, 'widget-id');
    },
    activeWidgetsIds() {
      return this.getUserWidgets.reduce((result, userWidget) => {
        const findedWidget = this.displayWidgets.find((widget) => widget['widget-id'] === userWidget['widget-id']);
        if (findedWidget) result.push(findedWidget.id);
        return result;
      }, []);
    },
  },
  methods: {
    ...mapActions({
      loadWidgets: LOAD_WIDGETS,
      changeWidgets: CHANGE_WIDGETS,
    }),

    async onWidgetsUpdate({ activeFacts }) {
      const attach = activeFacts
        .filter((item) => item['user-widget-id'] === null)
        .map((item) => item['widget-id']);

      const activeFactsWidgetsIds = activeFacts.map((item) => item['widget-id']);
      const detach = this.getUserWidgets
        .filter((item) => !activeFactsWidgetsIds.includes(item['widget-id']))
        .map((item) => item['widget-id']);

      const change = {
        attach: attach.length ? attach : null,
        detach: detach.length ? detach : null,
        order: activeFactsWidgetsIds.length ? activeFactsWidgetsIds : null,
      };
      await this.changeWidgets(change);
    },
  },
  created() {
    this.images = require.context('@/assets/icons/stats', false, /\.svg$/);
    this.loadWidgets();
  },
};
</script>
